import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F8F7F2',
      neutralLight3: '#F2F1EB',
      neutralDark3: '#40453B',
      neutralDark2: '#32362E',
      neutralDark1: '#000000',
      primaryDark: '#88947C',
      primaryLight: '#EDEBE2',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'the-seasons', sans-serif",
    label: "'greycliff-cf', sans-serif",
    paragraph: "'acumin-pro', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark3};
  font-family: ${theme.fontFamily.heading};
  font-weight: 300;
`

const descriptionStyle = css`
  color: ${theme.colors.variants.neutralDark3};
  font-family: ${theme.fontFamily.paragraph};
  font-weight: 300;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark3};
    font-family: ${theme.fontFamily.label};
    font-size: 0.75rem;
    letter-spacing: 0.2em;
    line-height: 1rem;
    margin: 0 auto 2.25rem;
    text-transform: uppercase;

    @media (max-width: 1199px) {
      margin-bottom: 1.125rem;
    }
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 6rem;
      line-height: 6rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 3rem;
        line-height: 3rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 4.6875rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.1875rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3.375rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.1875rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.25rem;

      @media (max-width: 1199px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 1.75rem;
      line-height: 2.25rem;

      @media (max-width: 1199px) {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.625rem;
      }
    `,
    xs: css`
      ${titleStyle}
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;

      @media (max-width: 1199px) {
        font-size: 1.125rem;
        line-height: 1.125rem;
      }
    `,
  },
  description: {
    large: css`
      ${descriptionStyle}
      font-size: 1.0625rem;
      line-height: 1.625rem;

      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    `,
    small: css`
      ${descriptionStyle}
      font-size: 1rem;
      line-height: 1.5rem;
    `,
  },
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({ children }: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          children
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
